import { Component, Input, HostBinding } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { checkHexBrightness } from '@core/helpers/color.helper';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'naris-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  standalone: true,
  imports: [IconComponent]
})
export class AvatarComponent {

  constructor(private readonly sanitizer: DomSanitizer) { }

  @Input()
  public size = 'medium';

  @Input()
  public color = 'default';

  @Input()
  public userColor: string | undefined;

  @Input()
  public label?: string;

  @Input()
  public icon?: string;

  @Input()
  public image: string;

  @Input()
  public active = false;

  @Input()
  public id: string;

  @HostBinding('style.background-image')
  get backgroundImage() {
    return this.image ? this.sanitizer.bypassSecurityTrustStyle(`url(${this.image})`) : 'none';
  }

  @HostBinding('class')
  get classes() {
    return [this.color, this.size, this.active];
  }

  @HostBinding('style')
  get myStyle(): SafeStyle {
    const luma = checkHexBrightness(this.userColor);
    const styleString = luma > 235 ? `background: ${this.userColor}; color: #6b8299;` : `background: ${this.userColor};`;
    return this.sanitizer.bypassSecurityTrustStyle(styleString);
  }
}
