import type { Point } from '@angular/cdk/drag-drop';
import type { IClosestColor, IColorPos } from '@core/models';

export const colorAtPos = (ctx: CanvasRenderingContext2D | null, x: number, y: number, type: 'hex' | 'rgb'): string => {
  const imageData = ctx?.getImageData(x, y, 1, 1).data;
  return type === 'hex' ? toHex(imageData) : toRGB(imageData);
};

export const toRGB = (data?: Uint8ClampedArray): string => !!data ? `rgb(${data[0]}, ${data[1]}, ${data[2]})` : 'rgb(0, 0, 0)';

export const toHex = (data?: number[] | Uint8ClampedArray): string => {
  if (!data) return '#000';
  const r = (data[0] | 1 << 8).toString(16).slice(1);
  const g = (data[1] | 1 << 8).toString(16).slice(1);
  const b = (data[2] | 1 << 8).toString(16).slice(1);
  return `#${r}${g}${b}`;
};

export const hex2Rgb = (hex: string, array = false): number[] | string | null => {
  if (!/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) return null;
  else {
    const hexCode = hex.substring(1).split('');
    if (hexCode.length === 3) {
      hexCode.push(hexCode[2]);
      hexCode.splice(1, 0, ...[hexCode[0], hexCode[1]]);
    }
    const hexString = `0x${hexCode.join('')}`;
    const rgbVal = [+hexString >> 16 & 255, +hexString >> 8  & 255, +hexString & 255];
    return array ? rgbVal : `rgb(${rgbVal.join(', ')})`;
  }
};

export const posOfColorY = (ctx: CanvasRenderingContext2D | null, canvas: HTMLCanvasElement, color: string): IColorPos => {
  const rgbVal = hex2Rgb(color, true) as number[];
  const data = ctx?.getImageData(0, 0, 1, canvas.height).data;
  const map: number[][] = [];
  for (let i = 0; i < (data?.length ?? 0); i += 4) {
    map.push([data![i], data![i + 1], data![i + 2]]);
  }
  const closest = closestTo(rgbVal, map);
  const closestColor = toHex(closest.color!);
  return {closestColor, colorPos: closest.pos as Point};
};

export const closestTo = (rgbValue: number[], dataMap: number[][], posY?: number): IClosestColor => dataMap.reduce<IClosestColor>((acc, color, index) => {
  const deviation = Math.sqrt((rgbValue[0] - color[0]) ** 2 + (rgbValue[1] - color[1]) ** 2 + (rgbValue[2] - color[2]) ** 2);
  const pos = typeof posY === 'number' ? {x: index, y: posY} : {x: 0, y: index};
  return !!acc.deviation && acc.deviation <= deviation ? acc : {deviation, color, pos};
}, {deviation: null, color: null, pos: {x: null, y: null}});

export const posOfColor = (ctx: CanvasRenderingContext2D | null, canvas: HTMLCanvasElement, color: string): Point => {
  const rgbVal = hex2Rgb(color, true) as number[];
  const data = ctx?.getImageData(0, 0, canvas.width, canvas.height).data;
  const closestColor = createPixelMap(data, canvas.height)
    .map((c, i) => closestTo(rgbVal, c, i))
    .reduce((a, c) => a.deviation! <= c.deviation! ? a : c);
  return closestColor.pos as Point;
};

export const createPixelMap = (data: Uint8ClampedArray | undefined, width: number): number[][][] => {
  const pixelMap = [];
  let counter = 1;
  let row: number[][] = [];
  for (let i = 0; i < (data?.length ?? 0); i += 4) {
    if (counter <= width) {
      row.push([data![i], data![i + 1], data![i + 2]]);
      counter++;
    } else {
      pixelMap.push(row);
      row = [[data![i], data![i + 1], data![i + 2]]];
      counter = 1;
    }
  }
  return pixelMap;
};

export const checkHexBrightness = (hexColor: string | undefined): number => {
  if (!!hexColor) {
    const c = hexColor.substring(1);      // strip #
    const rgb = parseInt(c, 16);   // convert rrggbb to decimal
    const r = rgb >> 16 & 0xff;  // extract red
    const g = rgb >>  8 & 0xff;  // extract green
    const b = rgb >>  0 & 0xff;  // extract blue

    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

    // luma value range is 0..255, where 0 is the darkest and 255 is the lightest.
    return luma;
  }

  return 0;
};
